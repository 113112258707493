import React from 'react';
import 'i18n';
import { BrowserRouter, Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';
import CallContext from 'models/callContext';

const Incoming = React.lazy(() => import('pages/incoming'));
const InCall = React.lazy(() => import('pages/inCall'));
const Outgoing = React.lazy(() => import('pages/outgoing'));
const Home = React.lazy(() => import('pages/home'));
const RoomCall = React.lazy(() => import('pages/room'));
const Direct = React.lazy(() => import('pages/direct'));

const defaultCallContext = {
	peer: null,
	myId: '',
	theirId: '',
	roomId: '',
	key: '',
	settings: null,
	myStream: null,
	theirStream: null,
	mediaConnection: null,
	dataConnection: null,
	roomConnection: null,
	cameraEnabled: true,
	micEnabled: true,
	recents: [],
	contacts: [],
	missedCalls: [],
	ringtone: new Audio('data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA')
};

let settingData = JSON.parse(localStorage.getItem('settings'));
if (!settingData) {
	settingData = JSON.stringify({ "volumeLevel": 2, "autoAnswering": false, "lastCameraState": true, "cameraStateSetting": 0, "videoResolution": 0, "notificationPerms": false });
	localStorage.setItem('settings', settingData);
	defaultCallContext.settings = JSON.parse(settingData);
} else {
	defaultCallContext.settings = settingData;
}

const DirectComponentWrapper = (props) => {
	let [searchParams] = useSearchParams();
	const id = searchParams.get('id');
	return <Direct calleeId={id} navigate={props.navigate} />;
};

function Pages() {
	let navigate = useNavigate();
	return (
		<Routes>
			<Route exact path="/" element={<Home navigate={navigate} />} />
			<Route path="/outgoing" element={<Outgoing navigate={navigate} />} />
			<Route path="/incoming" element={<Incoming navigate={navigate} />} />
			<Route path="/incall" element={<InCall navigate={navigate} />} />
			<Route path="/room" element={<RoomCall navigate={navigate} />} />
			<Route path="/direct" element={<DirectComponentWrapper navigate={navigate} />} />
		</Routes>
	);
}
function App() {
	return (
		<React.Suspense fallback={'Loading...'}>
			<CallContext.Provider value={defaultCallContext}>
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<Pages />
				</BrowserRouter>
			</CallContext.Provider>
		</React.Suspense>
	);
}

export default App;
